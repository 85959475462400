import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Views from '../components/Views';
import WhatsAppFAB from '../components/WhatsAppFAB';
import BackButton from '../components/BackButton';
import themes from '../components/Themes';

function Kad() {
  const { view } = useParams();
  const { theme } = useParams();
  const Bg = themes[theme];
  const Components = Views[view];

  return (
    <div className={`${Bg.textColor} ${Bg.themeColor}`}>
      <BackButton />
      {/* <WhatsAppFAB /> */}
      <Components.Hero />
      <Components.InviteMessage />
      <Components.Venue />
      <Components.Tentative />
      <Components.Countdown />
      {/* <Components.FAB /> */}
      <Components.BottomBar />
    </div>
  );
}

export default Kad;
