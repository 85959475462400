import React from 'react';
import { FaTools } from 'react-icons/fa';
import NavBar from '../components/NavBar';

const UnderMaintenance = () => {
  return (
    <div>
      <NavBar />
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <FaTools className="text-5xl text-red-500 mb-4" />
        <h1 className="text-2xl font-bold text-gray-800 mb-2">
          Site Under Maintenance
        </h1>
        <p className="text-gray-600">
          We are working hard to bring you a better experience.
        </p>
      </div>
    </div>
  );
};

export default UnderMaintenance;
