import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UnderMaintenance from './UnderMaintenance';
import Home from './Home';

function Main() {
  const [maintenance, setMaintenance] = useState(false);

  useEffect(() => {
    axios.get('https://api.jemputlah.com/api/settings')
      .then(response => setMaintenance(response.data.maintenance_mode))
      .catch(error => console.error('Error fetching settings:', error));
  }, []);

  return (
    <div>
      {maintenance ? <UnderMaintenance /> : <Home />}
    </div>
  );
}

export default Main