import React, { useEffect, useState } from 'react';
// import { gifts } from '../context/GiftList';
import axios from 'axios';

function GiftIdeas() {
  const [gifts, setGifts] = useState([]);

  useEffect(() => {
    // Fetch gifts from the API
    const fetchGifts = async () => {
      try {
        const response = await axios.get('https://api.jemputlah.com/api/show-gifts');
        // setLoading(false);
        setGifts(response.data);
      } catch (error) {
        console.error('Error fetching gifts:', error);
      }
    };

    fetchGifts();
  }, []);

  return (
    <div className='pt-8 space-y-2'>
      <h2 className='font-bold'>Idea Hadiah Kahwin</h2>
      <div className="flex flex-wrap">
        {gifts.map((gift) => (
          <div key={gift.id} className="w-1/2 p-1">
            <a href={gift.link} target="_blank" rel="noopener noreferrer">
              <div className="max-w-sm rounded overflow-hidden shadow-lg">
                <img 
                  src={`https://cdn.jemputlah.com/images/${gift.image}`}
                  alt={gift.title} 
                  className="w-full"
                  loading="lazy"
                />
                <div className="p-4 flex items-center justify-between">
                  <h2 className="text-sm">{gift.title}</h2>
                  {/* <h2 className="text-sm font-bold text-purple-400">RM{gift.price}</h2> */}
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default GiftIdeas