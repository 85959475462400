import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useScroll } from 'framer-motion';
import axios from 'axios';
import logo from '../img/logo-red.png';

import { Helmet } from 'react-helmet';
import Themes from '../components/Themes';
import ExpiredScreen from '../components/ExpiredScreen';
import LiveContent from '../components/live/LiveContent';
import LoadingScreen from '../components/LoadingScreen';

function Live() {
  const { id } = useParams();
  const { demoTheme } = useParams();

  const { scrollYProgress } = useScroll();
  
  const playerRef = useRef(null);
  const [isPlaying, setPlaying] = useState(false);

  const [loading, setLoading] = useState(true);
  const [hero, setHero] = useState(false);
  const [music, setMusic] = useState(true);
  const [ytId, setYtId] = useState('U4i_pEsQFz4');
  const [ytStart, setYtStart] = useState('0');
  const [scroll, setScroll] = useState('');

  const [theme, setTheme] = useState('');
  const [walimatulurus, setWalimatulurus] = useState('');
  const [walimatulurus2, setWalimatulurus2] = useState('');
  const [doa, setDoa] = useState('');
  const [groomName, setGroomName] = useState('');
  const [brideName, setBrideName] = useState('');
  const [weddingDate, setWeddingDate] = useState('');
  const [parentName, setParentName] = useState('');
  const [message, setMessage] = useState('');
  const [groomFullname, setGroomFullname] = useState('');
  const [brideFullname, setBrideFullname] = useState('');
  const [location, setLocation] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [tentative, setTentative] = useState('');
  const [contacts, setContacts] = useState('');
  const [directions, setDirections] = useState('');
  const [H2, setH2] = useState('');
  const [fontP, setFontP] = useState('');
  const [H2size, setH2size] = useState('');
  const [gift, setGift] = useState(true);
  const [salamKaut, setSalamKaut] = useState('');
  const [rsvp, setRsvp] = useState('');
  const [status, setStatus] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [expired, setExpired] = useState(false);
  
  document.body.style.overflow = scroll;

  const client = () => {
    axios.get(`https://api.jemputlah.com/api/clients/${id}`)
      .then(response => {
        const music = JSON.parse(response.data.music);
        const yt_id = response.data.yt_id;
        const start_time = response.data.start_time;
        const gift = response.data.gift;
        const salam_kaut = response.data.salam_kaut;
        const rsvp = response.data.rsvp;

        if (music) {
          setMusic(music);
          setScroll('hidden');
          if(yt_id){
            setYtId(yt_id);
          }
          if(start_time){
            setYtStart(start_time);
          }
          else {
            setYtId('U4i_pEsQFz4');
          }
          
        } else {
          setMusic(false);
          setScroll('auto');
          setHero(true);
        }

        if(gift) {
          setGift(gift);
        }
        if(salam_kaut) {
          setSalamKaut(salam_kaut);
        }
        if(rsvp) {
          setRsvp(JSON.parse(rsvp));
        }
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
  };  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.jemputlah.com/api/kad/${id}`);
        const data = response.data;
  
        setTheme(demoTheme || data.theme);
        setWalimatulurus(data.walimatulurus);
        setWalimatulurus2(data.walimatulurus2);
        setDoa(data.doa);
        setGroomName(data.groom_name);
        setBrideName(data.bride_name);
        setWeddingDate(data.wedding_date);
        setParentName(data.parent_name);
        setMessage(data.message);
        setGroomFullname(data.groom_fullname);
        setBrideFullname(data.bride_fullname);
        setLocation(data.location);
        setStartTime(data.start_time);
        setEndTime(data.end_time);
        setTentative(data.tentative);
        setContacts(data.contacts);
        setDirections(data.directions);
        setH2(data.H2);
        setFontP(data.fontP);
        setH2size(data.H2size);
        setStatus(data.status);
        setCreatedAt(data.created_at);
  
        await client();
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [id]);

  useEffect(() => {
    // Function to calculate the time difference
    const calculateTimeLeft = () => {
      const expirationTime = new Date(createdAt).getTime() + 24 * 60 * 60 * 1000;
      const currentTime = new Date().getTime();
      const difference = expirationTime - currentTime;

      if (difference > 0) {
        setExpired(false);
      } else {
        setExpired(true);
      }
    };

    // Update the countdown every second
    const interval = setInterval(calculateTimeLeft, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [createdAt]);
  
  const description = `${walimatulurus} | ${groomName} x ${brideName}`;

  const [heroStyle, setHeroStyle] = useState();
  const [textColor, setTextColor] = useState('text-black');
  const [xTopStyle, setXTopStyle] = useState('');
  const [xBottomStyle, setXBottomStyle] = useState('');

  useEffect(() => {
    if (theme) {
      const Components = Themes[theme];
      setHeroStyle(Components.themeColor);
      // setHeroStyle({
      //   backgroundImage: `url(${Components.bg})`,
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      // });
      setTextColor(Components.textColor);
      setXTopStyle(Components.xTopStyle);
      setXBottomStyle(Components.xBottomStyle);
      if(demoTheme) {
        setH2(Components.H2);
        setH2size(Components.H2size);
        setFontP(Components.fontP);
      }
    }
  }, [theme]);

  const [scrolling, setScrolling] = useState(false);
  const contentRef = useRef(null); // Reference to the content div
  const scrollAnimationFrame = useRef(null); // Ref to store the animation frame ID

  const startAutoScroll = () => {
    setScrolling(true);
    const maxScrollTop = contentRef.current.scrollHeight - window.innerHeight; // Maximum scrollable area

    const step = () => {
      const currentScrollTop = window.scrollY;
      const newScrollTop = Math.min(currentScrollTop + 1.5, maxScrollTop); // Scroll 1px at a time

      window.scrollTo(0, newScrollTop);

      if (newScrollTop < maxScrollTop) {
        scrollAnimationFrame.current = requestAnimationFrame(step); // Store animation frame ID in ref
      } else {
        setScrolling(false); // Stop when reaching the bottom
      }
    };

    scrollAnimationFrame.current = requestAnimationFrame(step); // Start scrolling
  };

  const stopAutoScroll = () => {
    // Cancel the scroll animation
    if (scrollAnimationFrame.current) {
      cancelAnimationFrame(scrollAnimationFrame.current); // Use ref to cancel the animation
      scrollAnimationFrame.current = null;
    }
    setScrolling(false);
  };

  // Function to stop auto scroll on user interaction
  const handleUserInteraction = () => {
    if (scrolling) {
      stopAutoScroll(); // Stop auto scrolling if user interacts
    }
  };

  useEffect(() => {
    // Add event listeners for user interaction
    window.addEventListener('wheel', handleUserInteraction); // Mouse scroll
    window.addEventListener('touchstart', handleUserInteraction); // Touch interaction (mobile)
    window.addEventListener('mousedown', handleUserInteraction); // Mouse down (dragging)

    return () => {
      // Clean up event listeners on component unmount
      window.removeEventListener('wheel', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
      window.removeEventListener('mousedown', handleUserInteraction);
    };
  }, [scrolling]); // Re-run when scrolling state changes

  return (
    <div>
      <Helmet>
        <title>{description}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content={description} />
      </Helmet>
      {status === 'unverified' ? (
      expired ? (
        <ExpiredScreen logo={logo} />
      ) : (
        loading ? <LoadingScreen logo={logo} /> : <LiveContent {...{
          contentRef, textColor, heroStyle, startAutoScroll, scrollYProgress, xTopStyle, xBottomStyle, playerRef,
          setPlaying, setHero, setScroll, hero, theme, walimatulurus, H2, fontP, H2size, groomName,
          brideName, weddingDate, walimatulurus2, parentName, message, groomFullname, brideFullname, 
          location, startTime, endTime, tentative, doa, music, ytId, ytStart, isPlaying, gift, contacts, 
          directions, salamKaut, rsvp
        }} />
      )
    ) : (
      loading ? <LoadingScreen logo={logo} /> : <LiveContent {...{
        contentRef, textColor, heroStyle, startAutoScroll, scrollYProgress, xTopStyle, xBottomStyle, playerRef,
        setPlaying, setHero, setScroll, hero, theme, walimatulurus, H2, fontP, H2size, groomName,
        brideName, weddingDate, walimatulurus2, parentName, message, groomFullname, brideFullname, 
        location, startTime, endTime, tentative, doa, music, ytId, ytStart, isPlaying, gift, contacts, 
        directions, salamKaut, rsvp
      }} />
    )}
    </div>
  );
}

export default Live;
