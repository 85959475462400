import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { items } from '../context/ItemList';

function Recommended() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Fetch items from the API
    const fetchItems = async () => {
      try {
        const response = await axios.get('https://api.jemputlah.com/api/show-items');
        // setLoading(false);
        setItems(response.data);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, []);

  return (
    <div className='pt-8 space-y-2'>
      <h2 className='font-bold'>Recommended</h2>
      <div className="flex flex-wrap">
        {items.map((item) => (
          <div key={item.id} className="w-full p-2">
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <div className="max-w-sm rounded overflow-hidden shadow-lg">
                <img 
                  src={`https://cdn.jemputlah.com/images/${item.image}`} 
                  alt={item.title} 
                  className="w-full"
                  loading="lazy"
                />
                <div className="p-4 flex items-center justify-between">
                  <h2 className="text-sm">{item.title}</h2>
                  <h2 className="text-sm font-bold text-purple-400">RM{item.price}</h2>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Recommended