// src/components/LiveContent.js
import LiveHero from './LiveHero';
import LiveInviteMessage from './LiveInviteMessage';
import LiveVenue from './LiveVenue';
import LiveTentative from './LiveTentative';
import LiveCountdown from './LiveCountdown';
import LiveUcapan from './LiveUcapan';
import LiveBottomBar from './LiveBottomBar';
import LiveIntro from './LiveIntro';
import BackgroundMusicSimple from '../BackgroundMusicSimple';
import LiveFooter from './LiveFooter';

const LiveContent = ({
  contentRef, textColor, heroStyle, startAutoScroll, scrollYProgress, xTopStyle, xBottomStyle, playerRef,
  setPlaying, setHero, setScroll, hero, theme, walimatulurus, H2, fontP, H2size, groomName, brideName,
  weddingDate, walimatulurus2, parentName, message, groomFullname, brideFullname, location,
  startTime, endTime, tentative, doa, music, ytId, ytStart, isPlaying, gift, contacts, directions,
  salamKaut, rsvp
}) => (
  <div ref={contentRef} className={`${textColor} overflow-hidden ${heroStyle}`}>
    <LiveHero {...{ startAutoScroll, scrollYProgress, xTopStyle, xBottomStyle, playerRef, setPlaying, setHero, setScroll, hero, theme, walimatulurus, H2, fontP, H2size, groomName, brideName, weddingDate }} />
    <LiveInviteMessage {...{ scrollYProgress, walimatulurus2, parentName, message, groomFullname, brideFullname, H2, fontP }} />
    <LiveVenue {...{ scrollYProgress, location, startTime, endTime, weddingDate, fontP }} />
    <LiveTentative {...{ scrollYProgress, xTopStyle, xBottomStyle, hero, theme, tentative, fontP }} />
    <LiveCountdown {...{ scrollYProgress, theme, doa, weddingDate, fontP }} />
    <LiveUcapan {...{ scrollYProgress, fontP }} />
    <div className="text-black">
      <LiveBottomBar {...{ scrollYProgress, gift, contacts, directions, weddingDate, startTime, endTime, fontP, salamKaut, rsvp }} />
    </div>
    {music && (
      <div>
        <LiveIntro {...{ playerRef, setPlaying, setHero, setScroll, theme, walimatulurus, H2, fontP, H2size, groomName, brideName }} />
        <BackgroundMusicSimple {...{ playerRef, setPlaying, isPlaying, videoId: ytId, videoStart: ytStart }} />
      </div>
    )}
    <LiveFooter />
  </div>
);

export default LiveContent;
