import React, { useState, useEffect } from 'react';
import Themes from '../Themes';
import { motion, useTransform } from 'framer-motion';

function LiveHero({ startAutoScroll, scrollYProgress, xTopStyle, xBottomStyle, hero, theme, walimatulurus, H2, fontP, H2size, groomName, brideName, weddingDate }) {

  const Components = Themes[theme];

  const weddingDateSet = new Date(weddingDate);
  const weddingDay = getDayFromDate(weddingDateSet);

  const formattedDate = weddingDateSet.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Function to calculate the day from a given date
  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    return days[dayIndex];
  }

  // const [opacity, setOpacity] = useState(1);

  const opacity = useTransform(scrollYProgress, [0,0.2,0.7], [1, 0, 1]);
  const xTop = useTransform(scrollYProgress, [0,0.2,0.7], [0, xTopStyle, 0]);
  const xBottom = useTransform(scrollYProgress, [0,0.2,0.7], [0, xBottomStyle, 0]);
  const scaleUp = useTransform(scrollYProgress, [0,0.2, 0.7], [1, 1.2, 1]);

  // useEffect(() => {
  //   return scrollYProgress.onChange((latest) => {
  //     if (latest >= 0.1) {
  //       setOpacity(transformedOpacity.get()); // Trigger useTransform for opacity
  //     }
  //   });
  // }, [scrollYProgress, transformedOpacity]);

  const ftImg = { opacity: opacity, translateX: xTop, scale: scaleUp };
  const fbImg = { opacity: opacity, translateX: xBottom, scale: scaleUp };

  // Step 2: Modify the center object to include the animation
  const center = {
    backgroundImage: `url(${Components.fc})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    opacity: opacity,
    // translateX: xTop,
    // scale: scaleUp
  };

  const heroStyle = {
    backgroundImage: `url(${Components.bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const groomNameLength = groomName.length;
  const brideNameLength = brideName.length;
  const letterDuration = 1.5; // duration for each letter
  const letterDelay = 0.2;    // delay between each letter

  // Calculate total delay time
  const totalNameRevealDelay = Math.max(groomNameLength, brideNameLength) * letterDelay + letterDuration;

  useEffect(() => {
    if(hero) {
      setTimeout(() => {
        startAutoScroll();
      }, totalNameRevealDelay*1000+1000);
    }
  }, [hero]);

  return (
    <div className="h-screen relative flex flex-col justify-center items-center" style={heroStyle}>
      <motion.div className={`${Components.fcStyle}`} style={center} >
      {/* <div className={`transition-opacity duration-500 ${hero ? 'opacity-100' : 'opacity-0'} ${Components.fcStyle}`} style={center} > */}
        <motion.p
          className={`text-l mb-8 ${fontP} uppercase`}
          initial={{ opacity: 0, y: 0 }}    // Start slightly below and invisible
          animate={hero ? { opacity: 1, y: 0 } : { opacity: 0, y: 0 }}     // Fade in and move to position
          transition={{
            duration: 0.5,                     // Duration for this element
            delay: totalNameRevealDelay - 1, // Delay to start after names reveal
            ease: "easeOut"                  // Smooth easing for the reveal
          }}
        >
          {walimatulurus}
        </motion.p>
        <div className={Components.centerText}>
          <h2 className={`${H2size} ${H2} capitalize`}>
            {groomName.split("").map((el, i) => (
              <motion.span
                initial={{ opacity: 0, y: 20 }}  // Start slightly below and invisible
                animate={hero ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}   // Fade in and move to position
                transition={{
                  duration: letterDuration,       // Duration for each letter
                  delay: i * letterDelay,         // Incremental delay for each letter
                  ease: "easeOut"                 // Smooth easing for the reveal
                }}
                key={i}
              >
                {el}
              </motion.span>
            ))}
          </h2>
          <motion.p
            className={`text-3xl ${H2} capitalize`}
            initial={{ opacity: 0, y: 0 }}    // Start slightly below and invisible
            animate={hero ? { opacity: 1, y: 0 } : { opacity: 0, y: 0 }}     // Fade in and move to position
            transition={{
              duration: letterDuration,                     // Duration for this element
              delay: letterDelay, // Delay to start after names reveal
              ease: "easeOut"                  // Smooth easing for the reveal
            }}
          >
            &
          </motion.p>
          <h2 className={`${H2size} ${H2} capitalize`}>
            {brideName.split("").map((el, i) => (
              <motion.span
                initial={{ opacity: 0, y: 20 }}  // Start slightly below and invisible
                animate={hero ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}   // Fade in and move to position
                transition={{
                  duration: letterDuration,       // Duration for each letter
                  delay: i * letterDelay,         // Incremental delay for each letter
                  ease: "easeOut"                 // Smooth easing for the reveal
                }}
                key={i}
              >
                {el}
              </motion.span>
            ))}
          </h2>
        </div>
        <motion.div
          className="mt-8"
          initial={{ opacity: 0, y: 0 }}  // Start slightly below and invisible
          animate={hero ? { opacity: 1, y: 0 } : { opacity: 0, y: 0 }}   // Fade in and move to position
          transition={{
            duration: 0.5,                   // Duration for this element
            delay: totalNameRevealDelay, // Additional delay after walimatulurus reveal
            ease: "easeOut"                // Smooth easing for the reveal
          }}
        >
          <p className={`text-l ${fontP} mb-2`}>
            {weddingDay}, {formattedDate}
          </p>
        </motion.div>
      </motion.div>
      <motion.img
        src={Components.ft}
        style={ftImg}
        className={Components.ftStyle}
      />
      <motion.img
        src={Components.fb}
        style={fbImg}
        className={Components.fbStyle}
      />
    </div>
  );
}

export default LiveHero;
