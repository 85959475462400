import React from "react";

const Footer = () => {
  return (
    <footer className="bg-red-400 text-white py-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
        {/* Left Section */}
        <div className="text-sm md:text-base">
          <p>&copy; {new Date().getFullYear()} Jemputlah.com | All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
